import {Card, CardContent, FormGroup} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useAuth0} from "@auth0/auth0-react";

enum PanicState {
  Off = 0,
  On = 1,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },
  }),
);


type PanicInfo = {
  state: PanicState
}

// This payload is being sent to panic API endpoint
type PanicEndpointPostPayload = {
  state: PanicState,
}

// This is response model for panic API endpoint
type PanicEndpointPostResponse = {
  status: string,
}

export default function PanicSwitch() {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  async function setPanicState(panicState: PanicState) {
    const accessToken = await getAccessTokenSilently();
    const data: PanicEndpointPostPayload = {
      state: panicState,
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/panic`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
      });
      const responseData: PanicEndpointPostResponse = await response.json();
      return responseData.status === "ok";
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <FormGroup row>
          <Button
              onClick={() => { setPanicState(PanicState.Off) }}
              variant="contained">Off</Button>
          <Button
              onClick={() => { setPanicState(PanicState.On) }}
              variant="contained"
              color="secondary">On</Button>
        </FormGroup>
      </CardContent>
    </Card>
  )
}