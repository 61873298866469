import React, {useEffect} from "react";
import SensorList from "../../components/SensorList";
import AlarmSwitch from "../../components/AlarmSwitch";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@material-ui/core/Box";
import {LogoutButton, LoginButton} from "../../components/LoginButton";
import PanicSwitch from "../../components/PanicSwitch";



export default function Dashboard() {
  useEffect(() => {
    document.title = "Thuis: Dashboard";
    // how much time it renders?
  })

  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    return (<Box m={1}><LoginButton/></Box>)
  }

  return (
    <>
      <SensorList/>
      <AlarmSwitch/>
      <PanicSwitch/>
      <Box m={1}><LogoutButton/></Box>
    </>
  )
};
