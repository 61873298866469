import {Card, CardContent, FormControlLabel, FormGroup} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import React, {useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useAuth0} from "@auth0/auth0-react";

enum AlarmState {
  Disarmed = 0,
  Armed = 1,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },
  }),
);


type AlarmInfo = {
  state: AlarmState
}

type AlarmEndpointResponse = {
  home_alarm: AlarmInfo,
}

type AlarmEndpointPostPayload = {
  state: AlarmState,
}

type AlarmEndpointPostResponse = {
  status: string,
}

export default function AlarmSwitch() {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [isChecked, setChecked] = useState<boolean>(false);
  const [hasErrors, setErrors] = useState(false);

  async function fetchData() {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/alarm`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        }
      });
      const data: AlarmEndpointResponse = await response.json();
      setChecked(data.home_alarm.state === AlarmState.Armed);
    } catch (e) {
      setErrors(e);
    }
  }

  async function setAlarmState(alarmState: AlarmState) {
    const accessToken = await getAccessTokenSilently();
    const data: AlarmEndpointPostPayload = {
      state: alarmState,
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/alarm`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    });
    const responseData: AlarmEndpointPostResponse = await response.json();
    return responseData.status === "ok";
  }

  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked;
    const newState = checked ? AlarmState.Armed : AlarmState.Disarmed;
    const success = await setAlarmState(newState);
    if(success) setChecked(checked);
  }

  useEffect(() => {
    fetchData();
  }, [])

  if (hasErrors) {
    console.error(hasErrors);
    return <p>Error fetching alarm state</p>
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={isChecked}
                onChange={handleChange}
                name="masterAlarmSwitch"
                color="primary"
              />
            }
            label="Master alarm"
          />
        </FormGroup>
      </CardContent>
    </Card>
  )
}