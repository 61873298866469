
export const DEFAULT_DATE_LOCALE = "en-GB";

export function formatDateString(dateStr: string | null): string {
    if (dateStr === null )
        return "Never";

    const formatOptions: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
    }
    const date = Date.parse(dateStr);
    const formatter = new Intl.DateTimeFormat(DEFAULT_DATE_LOCALE, formatOptions);
    return formatter.format(date);
}
