import React from 'react';
import {DefaultLayout} from "./components/Layout";
import {Switch, Route} from "react-router-dom";
import Dashboard from "./screens/Dashboard"

function App() {
  return (
    <DefaultLayout>
      <Switch>
        <Route exact path="/" component={Dashboard}/>
        {/*<PrivateRoute path="/catalogs" component={CatalogListPage}/>*/}
        {/*<PrivateRoute path="/browser" component={BrowserPage}/>*/}
      </Switch>
    </DefaultLayout>
  );
}

export default App;
