import React from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import Preloader from "./Preloader";
// import Copyright from "./Copyright";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    // background: {
    //   default: "#262431",
    // },
    // text: {
    //   primary: "#f1f1f8",
    // }
  }
})

const DefaultLayout: React.FunctionComponent = ({children}) => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/*<Preloader/>*/}
        {/*<Navbar/>*/}
        {children}
        {/*<Footer/>*/}
        {/*<Copyright/>*/}
      </ThemeProvider>
    </React.Fragment>
  )
};

export default DefaultLayout;