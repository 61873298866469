import {Avatar, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import React, {useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { formatDateString } from "../../utils";

const DOOR_SENSOR_TYPE = 0;
const DOOR_SENSOR_OPEN = 0;
const DOOR_SENSOR_CLOSE = 1;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },
  }),
);

type Device = {
  title: string,
  type: number,
  slug: string,
  visible: boolean,
  sequence: number,
}

type Event = {
  registered_at: string,
  value: number,
}

type DeviceItem = {
  device: Device,
  latest_event: Event,
}

type DoorSensorIconProps = {
  latestEvent: Event | null | undefined
}

function DoorSensorIcon({latestEvent}: DoorSensorIconProps) {
  if (latestEvent === null || latestEvent === undefined) {
    return <HelpOutlineIcon/>;
  }
  if (latestEvent.value === DOOR_SENSOR_OPEN) {
    return <LockOpenIcon/>
  } else if (latestEvent.value === DOOR_SENSOR_CLOSE) {
    return <LockIcon />
  } else {
    return <HelpOutlineIcon/>
  }
}

export default function SensorList() {

  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  const [hasError, setErrors] = useState(false);
  const [devices, setDevices] = useState<DeviceItem[]>([]);

  async function fetchData() {
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/devices?types=${DOOR_SENSOR_TYPE}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    });
    try {
      const data = await response.json();
      setDevices(data.devices);
    } catch (e) {
      setErrors(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (hasError) {
    return (<p>Error</p>)
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <List className={classes.root}>
          {devices.map(item => (
            <ListItem key={item.device.slug}>
              <ListItemAvatar>
                <Avatar>
                  <DoorSensorIcon latestEvent={item.latest_event} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={item.device.title}
                secondary={ item.latest_event ? formatDateString(item.latest_event.registered_at) : "Never"}
              />
            </ListItem>
            ))}
        </List>
      </CardContent>
    </Card>
  )
}